import { PopupWithModules } from '../../_includes/modules/popupModule/popupWithModules';

/**
  * Create a popup with modules
  * @param id - id of the popup, taken from the CMS
  * @param options - options for the popup (see below)
  * @param options.style - style of the popup, either dark, medium-dark, medium, medium-light or light
  * @param options.classes - CSS classes for the popup
  * @param options.animation - animation of the popup, either fade or slide
  * @param options.layout - layout of the popup, either small or large
  * @param options.track - track the popup, if true, will not show again if the popup has been shown
  * @param options.grouping - unique grouping name for the popup, used to track if the popup has been shown
  * @param options.onClose - function to be called on closing the popup
  * @param options.overlayOpacity - opacity of the overlay, a number between 0 and 1 (large popup only)
  * @param options.containerSize - size of the container, small, medium or large (large popup only)
  * @param options.overlayBlur - blur of the overlay, a number between 0 and 100 (large popup only)
  * @param options.maxWidth - max width of the popup, must contain the unit, e.g. 800px (small popup only)
  * @returns {PopupWithModules}
  */
  const createPopup = (id, options) => {
    const popupOptions= {
      overlayOpacity:  0.50,
      maxWidth: '800px',
      animation: 'fade',
      containerSize: 'small',
      layout: 'large',
      style: 'dark',
      overlayBlur: 30,
      track: false,
      ...options,
      loadImmediately: true,
    }
    return new PopupWithModules(id, popupOptions);
  };

window.createPopup = createPopup;

class PopupBase {
  openPopups = [];
  popupTypeOptions = new Map();

  constructor() {
    this.createPopupTypeOptions();
    globalThis.addEventListener('popup:open', event => {

      const popup = createPopup(
        event.detail.id,
        {
          ...(this.popupTypeOptions.get(event.detail.type) ?? {}),
          ...(event.detail.options ?? {}),
        });

      this.openPopups.push(popup);
    });
  }

  createPopupTypeOptions() {
    this.popupTypeOptions.set('FooterBanner', {
      loadImmediately: true,
      animation: 'slide',
      maxWidth: '100%',
      layout: 'small',
    });
  }
}

if (!globalThis._popupBase) {
  globalThis._popupBase = new PopupBase();
}
